import NextHead from "next/head";
import { useRouter } from "next/router";
import unescape from "lodash/unescape";
import { twitterHandle } from "@ecologi/react-components/src/consts/socials.constants";
import { getClientUrl } from "../lib";

const DEFAULT_IMAGE =
  "https://offset-earth-share-images.s3.eu-west-1.amazonaws.com/share-background.png";

const offWhite = "rgb(233, 228, 223)";
const siteName = "Ecologi";

export const Head = ({
  description,
  imageHeight,
  imageUrl,
  imageWidth,
  socialTitle,
  title: titleStart,
  url,
  noIndex,
}: {
  description?: string;
  imageHeight?: string;
  imageUrl?: string;
  imageWidth?: string;
  socialTitle?: string;
  title: string;
  url?: string;
  noIndex?: boolean;
}) => {
  const title = titleStart ? `${unescape(titleStart)} | ${siteName}` : siteName;
  const desc = unescape(description || "");
  const router = useRouter();
  const _url = url || getClientUrl(router.asPath);

  return (
    <NextHead>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
      />
      <meta name="theme-color" content="#1FAB74" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="fragment" content="!" />
      <meta name="apple-mobile-web-app-capable" content="yes" />

      {/* This meta tag is also set by `Document` in all non-production environments */}
      {noIndex ? <meta name="robots" content="noindex" /> : null}

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png?v=eEvbNpqje5"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png?v=eEvbNpqje5"
      />
      <link rel="shortcut icon" href="/favicon.ico?v=eEvbNpqje5" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=eEvbNpqje5"
      />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg?v=eEvbNpqje5"
        color="#20bf85"
      />
      <link
        href="/images/splashscreens/iphone5_splash.png"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/iphone6_splash.png"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/iphoneplus_splash.png"
        media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/iphonex_splash.png"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/iphonexr_splash.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/iphonexsmax_splash.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/ipad_splash.png"
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/ipadpro1_splash.png"
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/ipadpro3_splash.png"
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="/images/splashscreens/ipadpro2_splash.png"
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />

      <meta name="apple-mobile-web-app-title" content={siteName} />
      <meta name="application-name" content={siteName} />
      <meta name="msapplication-TileColor" content={offWhite} />
      <meta name="theme-color" content={offWhite} />

      <meta property="fb:app_id" content="2255982101156746" />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      <meta name="twitter:app:name:googleplay" content={siteName} />
      <meta name="twitter:app:name:ipad" content={siteName} />
      <meta name="twitter:site" content={`@${twitterHandle}`} />

      <meta name="twitter:card" content="summary_large_image" />
      <link rel="canonical" href={_url} />
      {desc && <meta name="description" content={desc} />}

      {/* Facebook overrides */}
      <meta property="og:title" content={socialTitle || titleStart} />
      <meta property="og:url" content={_url} />
      {desc && <meta property="og:description" content={desc} />}

      {imageWidth && <meta property="og:image:width" content={imageWidth} />}
      {imageHeight && <meta property="og:image:height" content={imageHeight} />}

      <meta
        name="msapplication-TileImage"
        content={imageUrl || DEFAULT_IMAGE}
      />

      <meta property="og:image" content={imageUrl || DEFAULT_IMAGE} />
      <meta name="twitter:image" content={imageUrl || DEFAULT_IMAGE} />
    </NextHead>
  );
};
