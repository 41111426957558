import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Wrapper, WrapperProps } from "../Wrapper";
import { EcologiTheme } from "../theme";
import { Typography } from "../Typography";
import { isImpactShop } from "../utils/is-impact-shop";
import * as bp from "@ecologi/react-components/src/theme/ecologi-breakpoints";
import { excludePropsFromForwarding } from "../utils";

export enum BackgroundVariant {
  CREAM = "CREAM",
  WHITE = "WHITE",
  GREY = "GREY",
  TRANSPARENT = "TRANSPARENT",
  MUSHROOM = "MUSHROOM",
  MIST = "MIST",
  MIST_LIGHT = "MIST_LIGHT",
  LIGHT_GREEN = "LIGHT_GREEN",
  DARK_BLUE = "DARK_BLUE",
  MIDNIGHT = "MIDNIGHT",
  CHARCOAL = "CHARCOAL",
}

export const backgroundMapper = {
  [BackgroundVariant.CREAM]: (theme: EcologiTheme) =>
    theme.colors.mushroomLight,
  [BackgroundVariant.WHITE]: (theme: EcologiTheme) => theme.colors.snowdrop,
  [BackgroundVariant.TRANSPARENT]: (theme: EcologiTheme) =>
    theme.colors.transparent,
  [BackgroundVariant.GREY]: (theme: EcologiTheme) => theme.colors.mist,
  [BackgroundVariant.MUSHROOM]: (theme: EcologiTheme) => theme.colors.mushroom,
  [BackgroundVariant.MIST]: (theme: EcologiTheme) => theme.colors.mist,
  [BackgroundVariant.MIST_LIGHT]: (theme: EcologiTheme) =>
    theme.colors.mistLight,
  [BackgroundVariant.LIGHT_GREEN]: (theme: EcologiTheme) =>
    theme.colors.lightGreen,
  [BackgroundVariant.DARK_BLUE]: (theme: EcologiTheme) => theme.colors.darkBlue,
  [BackgroundVariant.MIDNIGHT]: (theme: EcologiTheme) => theme.colors.midnight,
  [BackgroundVariant.CHARCOAL]: (theme: EcologiTheme) => theme.colors.charcoal,
};

export type BackgroundVariantProps = {
  backgroundVariant?: BackgroundVariant;
};

type SectionOuterProps = {
  isCompact?: boolean;
  paddingTopOverride?: string;
  slug?: string | string[];
  isImpactCards?: boolean;
} & BackgroundVariantProps;

export const backgroundVariantExtention = (
  props: {
    theme: EcologiTheme;
  } & BackgroundVariantProps
) => css`
  background-color: ${props?.backgroundVariant &&
  backgroundMapper[props?.backgroundVariant]
    ? backgroundMapper[props?.backgroundVariant](props.theme)
    : props.theme.colors.snowdrop};
`;

export const SectionBase = styled(
  "div",
  excludePropsFromForwarding("backgroundVariant")
)<BackgroundVariantProps>`
  ${backgroundVariantExtention}
`;

const SectionOuter = styled(SectionBase)<SectionOuterProps>`
  ${backgroundVariantExtention}

  ${(props) =>
    props?.slug === "impact-shop" && !props.isCompact
      ? css`
          @media ${props.theme.bp.mobileDown} {
            padding: 60px 0 50px 0;
          }
          @media ${props.theme.bp.mobileUp} {
            padding: 80px 0 190px 0;
          }
        `
      : props?.slug === "impact-shop" && props.isCompact
      ? css`
          @media ${props.theme.bp.mobileDown} {
            padding-top: 60px;
          }
          @media ${props.theme.bp.mobileUp} {
            padding-top: 80px;
            padding-bottom: 60px;
          }
        `
      : props.isCompact
      ? css`
          padding: 10px 0;
        `
      : css`
          padding: 60px 0;

          @media ${props.theme.bp.mobileUp} {
            padding: 86px 0;
            padding-top: ${props?.isImpactCards ? "0px" : "86px"};
          }
          @media ${props.theme.bp.mobileDown} {
            padding-top: ${props?.isImpactCards ? "10px" : "60px"};
          }
        `}

  ${(props) =>
    props.paddingTopOverride &&
    css`
      padding-top: ${props.paddingTopOverride} !important;
    `};
  ${(props) => zeroVariants[props?.slug ? (props.slug as string) : ""]}
`;

const zeroVariants = {
  zero_second: css`
    background: linear-gradient(
      180deg,
      rgba(246, 250, 250, 1) 0%,
      rgba(236, 244, 244, 1) 100%
    );
  `,
  zero_third: css`
    @media ${bp.mobileDown} {
      padding-top: 0px;
      padding-bottom: 120px;
    }
    @media ${bp.mobileUp} {
      padding-top: 0px;
      padding-bottom: 120px;
    }
  `,
};

type MarginBottomOverride = { marginBottomOverride?: string };

export type SectionProps = {
  children: React.ReactNode;
  slug?: string | string[];
  isImpactCards?: boolean;
} & WrapperProps &
  SectionOuterProps;

export function Section({
  backgroundVariant,
  children,
  isImpactCards,
  ...props
}: SectionProps) {
  return (
    <SectionOuter
      {...props}
      backgroundVariant={backgroundVariant}
      isImpactCards={isImpactCards}
    >
      <Wrapper {...props}>{children}</Wrapper>
    </SectionOuter>
  );
}

const Icon = styled.img`
  width: 92px;
  height: 100%;
  margin-bottom: 24px;
`;

const HeadingWrapper = styled(
  Wrapper,
  excludePropsFromForwarding("marginBottomOverride")
)<MarginBottomOverride>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottomOverride }) =>
    marginBottomOverride ? marginBottomOverride : "33px"};
`;

const BaseTitle = styled(Typography)`
  text-align: center;
  font-weight: bold;
  letter-spacing: 0px;
  overflow-wrap: break-word;
  line-height: 1.2;
  margin-bottom: 16px;
`;

const DefaultTitle = styled(BaseTitle)<{ isImpactShop?: boolean }>`
  font-size: 32px;
  word-wrap: break-word;
  width: 100%;

  @media ${(props) => props.theme.bp.mobileDown} {
    font-size: ${(props) => (props?.isImpactShop ? "26px" : "32px")};
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    font-size: 32px;
    ${(props) =>
      props?.isImpactShop
        ? css`
            font-size: 40px;
            width: 600px;
          `
        : css``}
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    ${(props) =>
      props?.isImpactShop
        ? css`
            width: 950px;
          `
        : css``}
  }
`;

const subtitleVariants = {
  zero_third: css`
    font-size: 18px;
    ${bp.mobileDown} {
      font-size: 14px;
    }
  `,
};

const Subtitle = styled(Typography)<{ isImpactShop?: boolean; slug?: string }>`
  text-align: center;
  margin-bottom: 20px;
  word-wrap: break-word;
  width: 100%;

  ${(props) =>
    props?.isImpactShop
      ? css`
          font-size: 19px;
        `
      : css``}
  ${(props) => subtitleVariants[props.slug as string]}
`;

const Link = styled(Typography)`
  position: relative;
  text-align: center;
  margin-bottom: 45px;
`;

export type SectionHeadingProps = {
  icon?: { url: string; alt: string };
  link?: string;
  subtitle?: string;
  title: string;
  slug?: string | string[];
} & MarginBottomOverride;

const SectionHeading = ({
  icon,
  subtitle,
  title,
  marginBottomOverride,
  slug,
}: SectionHeadingProps) => {
  return (
    <HeadingWrapper marginBottomOverride={marginBottomOverride}>
      {icon && <Icon src={icon.url} alt={icon.alt} />}
      <DefaultTitle
        use="featureTitle"
        as="h1"
        isImpactShop={isImpactShop(slug)}
      >
        {title}
      </DefaultTitle>
      {subtitle && (
        <Subtitle use="subtitle2" as="h2" isImpactShop={isImpactShop(slug)}>
          {subtitle}
        </Subtitle>
      )}
    </HeadingWrapper>
  );
};

Section.Heading = SectionHeading;
Section.Title = DefaultTitle;
Section.Subtitle = Subtitle;
Section.HeadingWrapper = HeadingWrapper;
Section.Icon = Icon;
Section.Link = Link;
Section.Outer = SectionOuter;
